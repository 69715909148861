import { ConfigProvider, theme } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useDarkMode } from "usehooks-ts";
import Home from "./components/home/Home";
import Layout from "./layout";
import UserOperation from "./components/userOperation/UserOperation";
import ErrorPage from "./components/error/ErrorPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Transaction } from "./components/transaction/Transaction";
import { Block } from "./components/block/Block";
import { RouteOption } from "./types/types";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      {
        path: `${RouteOption.userOperation}/:itemId`,
        element: <UserOperation />,
      },
      {
        path: `${RouteOption.transaction}/:itemId`,
        element: <Transaction />,
      },
      {
        path: `${RouteOption.block}/:itemId`,
        element: <Block />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);
const queryClient = new QueryClient();

export default function App() {
  const { isDarkMode } = useDarkMode();
  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "#b6cb5e" },
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ConfigProvider>
  );
}
