import ItemPage from "../common/ItemPage";
import TransactionDetails from "./TransactionDetails";

export function Transaction() {
  return (
    <ItemPage>
      <TransactionDetails />
    </ItemPage>
  );
}
