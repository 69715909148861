import ItemPage from "../common/ItemPage";
import UserOperationDetails from "./UserOperationDetails";

function UserOperation() {
  return (
    <ItemPage>
      <UserOperationDetails />
    </ItemPage>
  );
}

export default UserOperation;
