import React from "react";

export enum ChainId {
  "optimism" = 10,
  "polynomial" = 8008,
  "ethereum" = 1,
  "polynomial_testnet" = 8009,
  "base" = 8453,
}
export type UserOperationTableData = {
  userOperationHash: React.ReactNode;
  blockNumber: React.ReactNode;
  status: React.ReactNode;
  transactionHash: React.ReactNode;
  sender: string;
  fees: string;
};

export type UserOperation = {
  chainId: ChainId;
  blockNumber: number;
  txHash: string;
  timestamp: number;
  userOpHash: string;
  sender: string;
  success: boolean;
  actualGasCost: string;
};

export type UserOperationDetails = {
  _id: string;
  chainId: ChainId;
  blockHash: string;
  blockNumber: number;
  transactionHash: string;
  timestamp: number;
  userOpHash: string;
  sender: string;
  paymaster: string;
  nonce: string;
  success: boolean;
  actualGasCost: string;
  actualGasUsed: string;
  entrypoint: string;
  actualGasCostNum: string;
};

export type SearchQueryTypeOptions =
  | "userOpHash"
  | "transactionHash"
  | "blockNumber";

export type SearchQueryType = {
  type: SearchQueryTypeOptions;
};

export enum RouteOption {
  "userOperation" = "user-operation",
  "block" = "block",
  "transaction" = "transaction",
}
