import { Table } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  UserOperationDetails,
  UserOperationTableData,
} from "../../types/types";
import { shortenAddress } from "../../util/address";
import { readableTime } from "../../util/time";
import type { UserOperation } from "../../types/types";
import BigNumber from "bignumber.js";

const columns = [
  {
    title: "User Operation Hash",
    dataIndex: "userOperationHash",
    key: "userOperationHash",
  },
  {
    title: "Block Number",
    dataIndex: "blockNumber",
    key: "blockNumber",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Transaction Hash",
    dataIndex: "transactionHash",
    key: "transactionHash",
  },
  {
    title: "Sender",
    dataIndex: "sender",
    key: "sender",
  },
  {
    title: "Fees (ETH)",
    dataIndex: "fees",
    key: "fees",
  },
];

export default function UserOperationsTable({
  operationsData,
  isLoading,
  paginate,
  paginationCallback,
}: {
  operationsData: UserOperation[] | UserOperationDetails[] | undefined;
  isLoading?: boolean;
  paginate?: boolean;
  paginationCallback?: ({
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  }) => void;
}) {
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination: any) => {
    setTableParams({
      pagination,
    });
    if (paginationCallback) paginationCallback(pagination);
  };

  const transactionsToShow: UserOperationTableData[] | undefined =
    operationsData?.map((transaction) => ({
      key: transaction.userOpHash,
      userOperationHash: (
        <Link
          className="text-blue-500"
          to={`/user-operation/${transaction.userOpHash}`}
        >
          {shortenAddress(transaction.userOpHash)}
        </Link>
      ),
      status: (
        <div className="flex gap-2 no-wrap">
          {transaction.success ? (
            <CheckCircleOutlined className="text-lime-600" />
          ) : (
            <CloseCircleOutlined className="text-red-600" />
          )}
          <div className="text-nowrap">
            {readableTime(transaction.timestamp * 1000)}
          </div>
        </div>
      ),
      sender: shortenAddress(transaction.sender),
      fees: BigNumber(transaction.actualGasCost).dividedBy(1e18).toFixed(6),
      blockNumber: (
        <Link
          className="text-blue-500"
          to={`/block/${transaction.blockNumber}`}
        >
          {transaction.blockNumber}
        </Link>
      ),
      transactionHash: (
        <Link
          className="text-blue-500"
          to={`/transaction/${
            "txHash" in transaction
              ? transaction.txHash
              : transaction.transactionHash
          }`}
        >
          {shortenAddress(
            "txHash" in transaction
              ? transaction.txHash
              : transaction.transactionHash
          )}
        </Link>
      ),
    }));

  return (
    <Table
      loading={isLoading}
      dataSource={transactionsToShow}
      columns={columns}
      className="w-full"
      onChange={handleTableChange}
      pagination={
        paginate
          ? {
              ...tableParams.pagination,
              total: 1000,
              showSizeChanger: true,
            }
          : false
      }
    />
  );
}
