import type { ChainId } from "../../types/types";
import ChainIcon from "./ChainIcon";
import { CHAIN_PROPERTIES } from "../../config/constants";
export default function ChainAndIcon({ chainId }: { chainId: ChainId }) {
  return (
    <div className="flex items-center gap-2 justify-start">
      <ChainIcon chainId={chainId} className="w-3" />{" "}
      {CHAIN_PROPERTIES[chainId].displayName}
    </div>
  );
}
