import { Ethereum, Optimism } from "@thirdweb-dev/chain-icons";
import { ChainId } from "../../types/types";
import { LinkOutlined } from "@ant-design/icons";
export default function ChainIcon({
  chainId,
  className,
}: {
  chainId: ChainId;
  className?: string;
}) {
  if (chainId === ChainId.ethereum) {
    return <Ethereum className={className} />;
  } else if (chainId === ChainId.polynomial) {
    return (
      <img
        className={`${className} !w-4`}
        alt="polynomial"
        src={process.env.PUBLIC_URL + "/chain/polynomial.svg"}
      />
    );
  } else if (chainId === ChainId.optimism) {
    return <Optimism className={className} />;
  } else if (chainId === ChainId.base) {
    return (
      <img
        className={`${className} !w-4`}
        alt="base"
        src={process.env.PUBLIC_URL + "/chain/base.webp"}
      />
    );
  } else if (chainId === ChainId.polynomial_testnet) {
    return (
      <img
        className={`${className} !w-4`}
        alt="base"
        src={process.env.PUBLIC_URL + "/chain/polynomial-testnet.png"}
      />
    );
  } else {
    return <LinkOutlined />;
  }
}
