import { useQuery } from "@tanstack/react-query";
import { UserOperation } from "../../types/types";

export function useSearchUserOperations({
  limit,
  page,
  chainId,
}: {
  limit: number;
  page: number;
  chainId: number;
}) {
  const query = useQuery({
    queryKey: ["userOperations", { limit, page, chainId }],
    queryFn: async () => {
      const response = await fetch(
        `https://api.magicscan.xyz/recent-user-ops?limit=${limit}&page=${page}&chainId=${chainId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responsTyped: UserOperation[] = await response.json();
      return responsTyped;
    },
    staleTime: 1000 * 30, // 30 seconds
  });
  return query;
}
