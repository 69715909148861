import { Outlet } from "react-router-dom";
import NavHeader from "./components/header/Header";
import { useDarkMode } from "usehooks-ts";

export default function Layout() {
  const { isDarkMode } = useDarkMode();
  return (
    <div
      className={`${isDarkMode ? "dark" : ""} min-h-screen dark:bg-[#121212]`}
    >
      <NavHeader />
      <Outlet />
    </div>
  );
}
