import { DatabaseOutlined, SearchOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Typography } from "antd";
import ChainAndIcon from "../common/ChainAndIcon";
import {
  searchTypeToRouteMap,
  SUPPORTED_CHAIN_IDS,
} from "../../config/constants";
import { useState } from "react";
import { ChainId } from "../../types/types";
import { useNavigate } from "react-router-dom";
import UserOperationsResults from "./userOperationsResults/UserOperationsResults";
import { getSearchType } from "../../queries/useGetSearchType";

const { Search } = Input;

function Home() {
  const navigate = useNavigate();
  const [chain, setChain] = useState<ChainId>(ChainId.polynomial);
  return (
    <div className="w-full flex">
      <div className="w-full md:w-2/3 mx-auto">
        <div className="flex flex-col items-center p-4">
          <Typography.Title level={2} className="font-medium text-2xl mr-auto">
            User Operation Explorer <SearchOutlined className="ml-2" />
          </Typography.Title>
          <div className="my-10 w-full">
            <Search
              placeholder="Enter user operation hash / transaction hash / block number"
              enterButton="View"
              size="large"
              allowClear
              onSearch={async (value) => {
                const searchType = await getSearchType(value);
                navigate(`${searchTypeToRouteMap[searchType.type]}/${value}`);
              }}
            />
          </div>
          <div className="flex flex-col md:flex-row justify-between m:items-end w-full gap-2">
            <Typography.Text className="font-semibold text-xl">
              User Operations <DatabaseOutlined className="ml-2" />
            </Typography.Text>

            <Select
              className="min-w-60"
              value={chain}
              onChange={(value) => setChain(value)}
              optionFilterProp="label"
              options={SUPPORTED_CHAIN_IDS.map((chain) => ({
                label: <ChainAndIcon chainId={chain} />,
                value: chain,
              }))}
            />
          </div>
          <Divider />
          <div className="w-full overflow-x-auto">
            <UserOperationsResults chainId={chain} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
