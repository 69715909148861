import { useQuery } from "@tanstack/react-query";
import { UserOperationDetails } from "../../types/types";

export function useGetUserOperation({
  userOperationHash,
}: {
  userOperationHash: string | undefined;
}) {
  const query = useQuery({
    queryKey: ["userOperation", userOperationHash],
    queryFn: async () => {
      const response = await fetch(
        `https://api.magicscan.xyz/user-op/?userOpHash=${userOperationHash}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responsTyped: UserOperationDetails = await response.json();
      return responsTyped;
    },
    staleTime: 1000 * 30, // 30 seconds
    enabled: !!userOperationHash,
  });
  return query;
}
