import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { Card, Divider, Skeleton, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import ChainAndIcon from "../common/ChainAndIcon";
import { useGetUserOperation } from "../../queries/hooks/useGetUserOperation";
import { ItemPageProperty } from "./ItemPageProperty";
import { RouteOption } from "../../types/types";
import { CHAIN_PROPERTIES } from "../../config/constants";
import BigNumber from "bignumber.js";

function UserOperationDetails() {
  const paramData = useParams();
  const itemId = paramData.itemId;

  const {
    data: transactionData,
    isLoading,
    isError,
  } = useGetUserOperation({
    userOperationHash: itemId,
  });
  if (isLoading) {
    return <Skeleton active className="my-10" />;
  }
  if (isError) {
    return (
      <div className="flex flex-col items-center  w-full h-[50vh] justify-center gap-4">
        <CloseCircleOutlined className="text-red-400 text-5xl" />

        <Typography.Text className="text-lg">
          User operation not found
        </Typography.Text>
        <Card className="min-w-60">
          <Typography.Text copyable ellipsis>
            {itemId}
          </Typography.Text>
        </Card>
      </div>
    );
  }
  return (
    <>
      {itemId && transactionData && (
        <Card
          title="User operation hash"
          bordered={false}
          size="small"
          className="w-full border dark:border-none my-10"
        >
          <div className="flex gap-6 items-center">
            <ChainAndIcon chainId={transactionData.chainId} />
            <Typography.Text copyable>{paramData.itemId}</Typography.Text>
          </div>
        </Card>
      )}
      {itemId && transactionData && (
        <div className="w-full">
          <Divider orientation="left">
            <ContainerOutlined /> Transaction details
          </Divider>
          <div className="divide-y dark:divide-zinc-900 divide-gray-200 border border-gray-200 dark:border-zinc-900 rounded-lg shadow-md dark:bg-[#141414] bg-transparent">
            <ItemPageProperty
              propertyName="Transaction Hash"
              propertyValue={
                <Link
                  className="text-blue-500"
                  to={`/${RouteOption.transaction}/${transactionData.transactionHash}`}
                >
                  {transactionData.transactionHash}
                </Link>
              }
              additionalCopyableValue={transactionData.transactionHash}
              externalLink={`${
                CHAIN_PROPERTIES[transactionData.chainId]
                  .explorerBaseTransactionUrl
              }/${transactionData.transactionHash}`}
            />
            <ItemPageProperty
              propertyName="Sender"
              propertyValue={transactionData.sender}
              valueCopyable
              externalLink={`${
                CHAIN_PROPERTIES[transactionData.chainId].explorerBaseAddressUrl
              }/${transactionData.sender}`}
            />
            <ItemPageProperty
              propertyName="Entry Point"
              propertyValue={transactionData.entrypoint}
            />
            <ItemPageProperty
              propertyName="Block Number"
              propertyValue={
                <Link
                  className="text-blue-500"
                  to={`/${RouteOption.block}/${transactionData.blockNumber}`}
                >
                  {transactionData.blockNumber}
                </Link>
              }
              additionalCopyableValue={transactionData.blockNumber}
              externalLink={`${
                CHAIN_PROPERTIES[transactionData.chainId].explorerBaseBlockUrl
              }/${transactionData.blockNumber}`}
            />
            <ItemPageProperty
              propertyName="Status"
              propertyValue={
                transactionData.success ? (
                  <div className="flex gap-2 items-center">
                    <CheckCircleOutlined className="text-lime-600" /> Success
                  </div>
                ) : (
                  <div className="flex gap-2 items-center">
                    <CloseCircleOutlined className="text-red-600" /> Failed
                  </div>
                )
              }
            />
            <ItemPageProperty
              propertyName="Paymaster"
              propertyValue={transactionData.paymaster}
              valueCopyable
              externalLink={`${
                CHAIN_PROPERTIES[transactionData.chainId].explorerBaseAddressUrl
              }/${transactionData.paymaster}`}
            />
            <ItemPageProperty
              propertyName="Time"
              propertyValue={new Date(
                transactionData.timestamp * 1000
              ).toLocaleString()}
            />
            <ItemPageProperty
              propertyName="Fees"
              propertyValue={
                <div>
                  {BigNumber(transactionData.actualGasCost)
                    .dividedBy(1e18)
                    .toString()}{" "}
                  ETH
                </div>
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UserOperationDetails;
