import ItemPage from "../common/ItemPage";
import BlockDetails from "./BlockDetails";

export function Block() {
  return (
    <ItemPage>
      <BlockDetails />
    </ItemPage>
  );
}
