import { CloseCircleOutlined, ContainerOutlined } from "@ant-design/icons";
import { Card, Divider, Skeleton, Typography } from "antd";
import { useParams } from "react-router-dom";
import ChainAndIcon from "../common/ChainAndIcon";
import UserOperationsTable from "../common/UserOperationsTable";
import { useGetBlockUserOperation } from "../../queries/hooks/useGetBlockUserTransactions";

function BlockDetails() {
  const paramData = useParams();
  const itemId = paramData.itemId;

  const {
    data: blockData,
    isLoading,
    isError,
  } = useGetBlockUserOperation({
    txHash: itemId,
  });
  if (isLoading) {
    return <Skeleton active className="my-10" />;
  }
  if (isError) {
    return (
      <div className="flex flex-col items-center  w-full h-[50vh] justify-center gap-4">
        <CloseCircleOutlined className="text-red-400 text-5xl" />

        <Typography.Text className="text-lg">Block not found</Typography.Text>
        <Card className="min-w-60">
          <Typography.Text copyable ellipsis>
            {itemId}
          </Typography.Text>
        </Card>
      </div>
    );
  }
  return (
    <>
      {itemId && blockData && (
        <Card
          title="Block number"
          bordered={false}
          size="small"
          className="w-full border dark:border-none my-10"
        >
          <div className="flex gap-6 items-center">
            {blockData && blockData.length > 0 && (
              <ChainAndIcon chainId={blockData[0].chainId} />
            )}
            <Typography.Text copyable>{paramData.itemId}</Typography.Text>
          </div>
        </Card>
      )}
      {itemId && blockData && (
        <div className="w-full">
          <Divider orientation="left">
            <ContainerOutlined /> User Operations
          </Divider>
          <div className="divide-y dark:divide-zinc-900 divide-gray-200 border border-gray-200 dark:border-zinc-900 rounded-lg shadow-md dark:bg-[#141414] bg-transparent">
            <UserOperationsTable
              operationsData={blockData}
              isLoading={isLoading}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default BlockDetails;
