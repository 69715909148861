import { Typography } from "antd";
import React from "react";
import { RxExternalLink } from "react-icons/rx";
export function ItemPageProperty({
  propertyName,
  propertyValue,
  valueCopyable,
  additionalCopyableValue,
  externalLink,
}: {
  propertyName: string;
  propertyValue: string | number | JSX.Element;
  valueCopyable?: boolean;
  additionalCopyableValue?: string | number;
  externalLink?: string;
}) {
  return (
    <div className="w-full flex px-6 py-3">
      <Typography.Text className="w-1/3">{propertyName}</Typography.Text>
      <div className="flex gap-2 flex-nowrap w-2/3 items-center">
        <div className="flex flex-nowrap gap-1 items-center">
          <Typography.Text copyable={!!valueCopyable}>
            {propertyValue}
          </Typography.Text>
          {additionalCopyableValue && (
            <Typography.Text
              className="mt-1"
              copyable={{
                text: String(additionalCopyableValue),
              }}
            />
          )}
        </div>
        {externalLink && (
          <a href={externalLink} target="_blank" rel="noreferrer">
            <RxExternalLink className="text-gray-600 dark:text-gray-400" />
          </a>
        )}
      </div>
    </div>
  );
}
