import { ChainId, RouteOption, SearchQueryTypeOptions } from "../types/types";

export const SUPPORTED_CHAIN_IDS: ChainId[] = [
  ChainId.polynomial,
  ChainId.optimism,
  ChainId.ethereum,
  ChainId.base,
  ChainId.polynomial_testnet,
];

export const CHAIN_PROPERTIES: Record<
  ChainId,
  {
    id: number;
    displayName: string;
    explorerBaseTransactionUrl: string;
    explorerBaseBlockUrl: string;
    explorerBaseAddressUrl: string;
  }
> = {
  1: {
    id: ChainId.ethereum,
    displayName: "Ethereum",
    explorerBaseBlockUrl: "https://etherscan.io/block",
    explorerBaseTransactionUrl: "https://etherscan.io/tx",
    explorerBaseAddressUrl: "https://etherscan.io/address",
  },
  8008: {
    id: ChainId.polynomial,
    displayName: "Polynomial",
    explorerBaseBlockUrl: "https://polynomialscan.io/block",
    explorerBaseTransactionUrl: "https://polynomialscan.io/tx",
    explorerBaseAddressUrl: "https://polynomialscan.io/address",
  },
  10: {
    id: ChainId.optimism,
    displayName: "Optimism",
    explorerBaseBlockUrl: "https://optimistic.etherscan.io/block",
    explorerBaseTransactionUrl: "https://optimistic.etherscan.io/tx",
    explorerBaseAddressUrl: "https://optimistic.etherscan.io/address",
  },
  8009: {
    id: ChainId.polynomial_testnet,
    displayName: "Polynmoial Testnet",
    explorerBaseBlockUrl: "https://sepolia.polynomialscan.io/block",
    explorerBaseTransactionUrl: "https://sepolia.polynomialscan.io/tx",
    explorerBaseAddressUrl: "https://sepolia.polynomialscan.io/address",
  },
  8453: {
    id: ChainId.base,
    displayName: "Base",
    explorerBaseBlockUrl: "https://basescan.org/block",
    explorerBaseTransactionUrl: "https://basescan.org/tx",
    explorerBaseAddressUrl: "https://basescan.org/address",
  },
};

export const searchTypeToRouteMap: Record<SearchQueryTypeOptions, RouteOption> =
  {
    blockNumber: RouteOption.block,
    transactionHash: RouteOption.transaction,
    userOpHash: RouteOption.userOperation,
  };
