import { useSearchUserOperations } from "../../../queries/hooks/useSearchUserOperations";
import { useState } from "react";
import { ChainId } from "../../../types/types";
import UserOperationsTable from "../../common/UserOperationsTable";

export default function UserOperationsResults({
  chainId,
}: {
  chainId: ChainId;
}) {
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const { data, isLoading } = useSearchUserOperations({
    chainId: chainId,
    limit: tableParams.pagination.pageSize,
    page: tableParams.pagination.current,
  });

  const handleTableChange = (pagination: {
    current: number;
    pageSize: number;
  }) => {
    setTableParams({
      pagination,
    });
  };

  return (
    <UserOperationsTable
      isLoading={isLoading}
      paginationCallback={handleTableChange}
      paginate
      operationsData={data}
    />
  );
}
