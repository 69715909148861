export function readableTime(timestamp: number): string {
  const date = new Date(timestamp);
  return date.toLocaleString();
}

export function timePassed(timestamp: number): string {
  const timePassed = Date.now() - timestamp;
  if (timePassed < 1000) {
    return "just now";
  }
  if (timePassed < 1000 * 60) {
    return `${Math.floor(timePassed / 1000)}s ago`;
  }
  if (timePassed < 1000 * 60 * 60) {
    return `${Math.floor(timePassed / (1000 * 60))}m ago`;
  }
  if (timePassed < 1000 * 60 * 60 * 24) {
    return `${Math.floor(timePassed / (1000 * 60 * 60))}h ago`;
  }
  if (timePassed < 1000 * 60 * 60 * 24 * 7) {
    return `${Math.floor(timePassed / (1000 * 60 * 60 * 24))}d ago`;
  }
  if (timePassed < 1000 * 60 * 60 * 24 * 7 * 4) {
    return `${Math.floor(timePassed / (1000 * 60 * 60 * 24 * 7))}w ago`;
  }
  return `${Math.floor(timePassed / (1000 * 60 * 60 * 24 * 7 * 4))}m ago`;
}
