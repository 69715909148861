import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

function ItemPage({ children }: { children: React.ReactNode }) {
  return (
    <div className="w-full flex">
      <div className="w-full md:w-2/3 mx-auto">
        <div className="flex flex-col items-start p-4">
          <Link to="/" className="text-blue-500">
            <ArrowLeftOutlined /> Home
          </Link>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ItemPage;
