import { SearchQueryType } from "../types/types";

export async function getSearchType(searchQuery: string) {
  const response = await fetch(
    `https://api.magicscan.xyz/search-query?query=${searchQuery}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const responsTyped: SearchQueryType = await response.json();
  return responsTyped;
}
